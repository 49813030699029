import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { InventoryClient } from "../../../clients/InventoryClient"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import InventoryResultCard from "../Inventory/InventoryResultCard"
import { SimilarVehiclesProps } from "./InventoryDetail.d"
import { ContactContext } from "../../../contexts/Contact"
import {
  openContactDealerModal,
  updateField,
} from "../../../contexts/Contact/actions"
import { Button } from "../../atoms/Button"

/**
 * @author Cody
 *
 * @param {Object} similarVehicles object containing similar vehicles
 * @returns <section></section>
 *
 */

const SimilarVehicles: React.FC<SimilarVehiclesProps> = ({ vehicle }) => {
  const [{ dealers, radius }] = useContext(LocationContext)
  const [similarVehicles, setSimilarVehicles] = useState([])
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(ContactContext)
  const [showNoSimilarInventory, setShowNoSimilarInventory] = useState(false)

  const fetchSimilarVehicles = async (params: any) => {
    try {
      let inventory = await InventoryClient.get(params)
      // Remove current vehicle from similar vehicles
      const vehicles = inventory?.vehicles?.filter(
        similarVehicle => vehicle?.vin !== similarVehicle?.vin
      )
      return vehicles
    } catch (err) {
      console.log("Error:", err?.message)
      return []
    }
  }

  useEffect(() => {
    const getSimilarVehicles = async () => {
      let inventory
      // Get vehicles that have the same dealer code, series, and model
      inventory = await fetchSimilarVehicles({
        series: vehicle.series,
        modelNumber: vehicle.modelNumber,
        dealer: vehicle.dealer,
        limit: 3,
        include: ["accessories"],
      })
      if (inventory?.length > 0) {
        setSimilarVehicles(inventory)
        return
      }
      // Get vehicles with the same dealer code and series
      inventory = await fetchSimilarVehicles({
        series: vehicle.series,
        dealer: vehicle.dealer,
        limit: 3,
        include: ["accessories"],
      })
      if (inventory?.length > 0) {
        setSimilarVehicles(inventory)
        return
      }

      // Get vehicles with the same series and model
      const dealerIds = dealers?.map(dealer => dealer?.DealerCode).join(",")
      inventory = await fetchSimilarVehicles({
        series: vehicle.series,
        modelNumber: vehicle.modelNumber,
        dealer: dealerIds,
        limit: 3,
        include: ["accessories"],
      })
      if (inventory?.length > 0) {
        setSimilarVehicles(inventory)
        return
      }

      // Get vehicles with the same series
      inventory = await fetchSimilarVehicles({
        series: vehicle.series,
        dealer: dealerIds,
        limit: 3,
        include: ["accessories"],
      })
      if (inventory?.length > 0) {
        setSimilarVehicles(inventory)
        return
      }

      // No similar vehicles returned
      setShowNoSimilarInventory(true)
    }

    if (!dealers || !vehicle) return
    getSimilarVehicles()
  }, [vehicle, dealers])

  const handleContactDealer = () => {
    dispatch(openContactDealerModal(null, null, "New Inventory Availability"))
    let formattedFiltersString = `Series: ${vehicle.series}, Model: ${vehicle.modelNumber}`

    let comment = _("I'm interested in the") + " " + formattedFiltersString
    dispatch(updateField("comments", comment))
    dispatch(updateField("seriesName", vehicle.series))
    dispatch(updateField("trim", vehicle.grade))
  }

  return (
    <section
      aria-label="Similar Vehicle Section"
      css={[
        tw`relative flex items-end min-h-[32rem] pb-10`,
        tw`md:(pb-40)`,
        tw`lg:(min-h-0)`,
      ]}
    >
      <section css={[tw`w-full`, tw`lg:(px-4)`]}>
        <h3
          css={[
            tw`text-black text-3xl tracking-[3px] font-light ml-6 mt-12`,
            tw`lg:(text-3xl tracking-[3.75px] my-[3.75rem]) my-12`,
          ]}
        >
          {_("Similar Vehicles")}
        </h3>
        {similarVehicles?.length > 0 && (
          <section
            css={[
              tw`justify-center items-start gap-5`,
              tw`md:(grid grid-cols-2 gap-5 mx-6)`,
              tw`xl:(flex gap-10)`,
            ]}
          >
            {similarVehicles.map((similarVehicle, i) => (
              <article
                aria-label="Similar Vehicle"
                css={[
                  tw`flex mb-6 flex-col items-center justify-center`,
                  tw`md:(col-span-1)`,
                  tw`xl:(flex-row)`,
                ]}
              >
                <InventoryResultCard vehicle={similarVehicle} index={i} />
              </article>
            ))}
          </section>
        )}
        {showNoSimilarInventory && (
          <div
            css={[
              tw`bg-gray-100 rounded-lg p-16 text-center mt-20 mx-auto max-w-desktop`,
            ]}
          >
            <p css={[tw`font-bold text-lg`]}>
              {_(
                "Sorry, there are no exact inventory matches for your selection within"
              )}{" "}
              {radius} {_("miles of your ZIP code.")}
            </p>
            <div css={[tw`pt-6 flex justify-center gap-4`]}>
              <Button primary onClick={handleContactDealer}>
                {_("Contact a Dealer")}
              </Button>
            </div>
          </div>
        )}
      </section>
    </section>
  )
}

export default SimilarVehicles
