import React from "react"
import tw from "twin.macro"
import { SimpleGallery } from "../../molecules/SimpleGallery"
import { VehicleGalleryProps } from "./InventoryDetail.d"

const VehicleGallery: React.FC<VehicleGalleryProps> = ({
  vehicleImages,
  vehicle,
  analyticsId,
  vehicleEligibility = true,
  imageType,
  location,
  index = 0,
}) => {
  return (
    <div css={[tw`w-full row-start-1`, tw`md:(w-full)`]}>
      <section css={[tw`pt-0 relative`, tw`lg:(m-12)`, tw`2xl:(m-12 mx-40)`]}>
        <SimpleGallery
          slides={vehicleImages}
          inventory
          inventoryData={vehicle}
          analyticsId={analyticsId}
          chevronPosition="inside"
          vehicleEligibility={vehicleEligibility}
          imageType={imageType}
          location={location}
          vehiclePosition={index}
        />
      </section>
    </div>
  )
}

export default VehicleGallery
