import { navigate } from "gatsby"
import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import Icon from "../Icon"
import { Link } from "../Link"
import { BackProps } from "./Back.d"

/**
 * Navigation link used on details pages to allow the user to navigate back to a list view
 *
 * @component
 * @param {BackProps} props
 * @param {link} props.link - path for navigation
 *
 * @returns <Link></Link>
 */
const Back: React.FC<BackProps> = ({
  link,
  cta,
  analyticsId,
  onClick,
  ...remainingProps
}) => {
  const languageObj = useContext(LanguageContext)

  // If link is not provided, navigate to the previous page.
  const handleClick = () => {
    if (!link) {
      if (window?.previousPath) {
        navigate(window?.previousPath)
      } else {
        // If the previous url has a different domain, navigate to the home page.
        navigate("/")
      }
    }
    if (onClick) {
      onClick()
    }
  }
  return (
    <div css={[tw`flex items-center`]}>
      <Icon.Chevron color="toyotaRed" direction="left" css={[tw`h-3 mr-2`]} />
      <Link
        animated
        animatedThin
        to={link}
        onClick={() => {
          handleClick()
        }}
        css={[
          tw`text-toyotaRed inline-flex items-center pl-0 text-sm normal-case py-0 hover:(cursor-pointer)`,
          tw`md:(font-book text-lg)`,
        ]}
        analytics-id={analyticsId}
        target="_self"
        forceExternal={true}
        {...remainingProps}
      >
        <span css={[tw`inline-flex whitespace-nowrap`]}>{cta}</span>
      </Link>
    </div>
  )
}

export default Back
