import React, { useContext } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { LanguageContext } from "../../../contexts/Language"
import {
  getSeriesMpgDisclaimerCode,
  parseDisclaimerBlocks,
} from "../../../helpers"
import { VehicleHighlightProps } from "./InventoryDetail.d"

const Highlights: React.FC<VehicleHighlightProps> = ({ vehicle }) => {
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  const mpgDisclaimerCode = getSeriesMpgDisclaimerCode(
    vehicle?.series,
    vehicle?.year
  )
  return (
    <section
      aria-label="Vehicle Highlights Section"
      css={[
        tw`w-full px-6 row-start-1 row-end-1 pb-8`,
        tw`lg:(w-[32rem] h-auto px-10 mt-10)`,
      ]}
    >
      <h2 css={[tw`text-3xl font-light tracking-widest mb-3`]}>
        {_("Highlights")}
      </h2>
      {vehicle.mpg && (
        <>
          <span css={tw`font-bold block`}>{_("MPG")}</span>
          <span css={tw`block`}>{_("(city/highway/combined)")}</span>
          <span css={tw`block pb-4`}>
            {vehicle.mpg.city}/{vehicle.mpg.highway}/{vehicle.mpg.combined}
            {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
              dispatch(toggleDisclaimersModal(code))
            )}
          </span>
        </>
      )}
      {vehicle.characteristics &&
        vehicle.characteristics.length > 0 &&
        vehicle.characteristics.map(
          (characteristic: { type: string; title: string }) => (
            <>
              <span css={tw`font-bold block capitalize`}>
                {characteristic.type}
              </span>
              {characteristic.title !== characteristic.type ? (
                <span css={tw`block pb-4`}>{characteristic.title}</span>
              ) : (
                <span css={tw`block pb-4`}>{characteristic.description}</span>
              )}
            </>
          )
        )}
    </section>
  )
}

export default Highlights
