import tw from "twin.macro"
import { useEffect, useRef, useState } from "react"
import Icon from "../../atoms/Icon"
import { HorizontalScrollerProps } from "./HorizontalScroller.d"

export default function HorizontalScroller({
  children,
}: HorizontalScrollerProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [hideButtons, setHideButtons] = useState<boolean>(true)
  const [scrollPosition, setScrollPosition] = useState<"start" | "end" | "mid">(
    "start"
  )

  useEffect(() => {
    const container = containerRef.current
    if (!container) return
    const resizeHandler = () => {
      if (!container) setHideButtons(true)
      const divs = container.children
      if (!divs) setHideButtons(true)

      const containerWidth = container.clientWidth
      const totalWidth = Array.from(divs).reduce(
        (acc, div) => acc + div.clientWidth,
        0
      )
      // Based on hard coded gap of 20px
      const gap = (divs.length - 1) * 20

      if (totalWidth + gap > containerWidth) {
        setHideButtons(false)
      } else {
        setHideButtons(true)
      }
    }
    resizeHandler()
    const scrollHandler = () => {
      if (container.scrollLeft === 0) {
        setScrollPosition("start")
      } else if (
        container.scrollLeft ===
        container.scrollWidth - container.clientWidth
      ) {
        setScrollPosition("end")
      } else {
        scrollPosition !== "mid" && setScrollPosition("mid")
      }
    }
    container.addEventListener("scroll", scrollHandler)
    window.addEventListener("resize", resizeHandler)

    return () => {
      container.removeEventListener("scroll", scrollHandler)
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  const scrollToFirstIncompleteDiv = (dir: "prev" | "next") => {
    const container = containerRef.current

    if (container) {
      // Get all child divs within the container
      const divs = container.children
      if (!divs) return
      // Find the first div that is not completely within the view
      let targetDiv

      for (let i = 0; i < divs.length; i++) {
        const div = divs[i]
        const rect = div.getBoundingClientRect()

        if (dir === "next") {
          if (rect.right > container.clientWidth) {
            // This div is partially or completely outside the view
            targetDiv = div
            break
          }
        } else {
          if (rect.left < container.getBoundingClientRect().left) {
            // This div is completely to the left, out of view
            targetDiv = div
            break
          }
        }
      }

      if (targetDiv) {
        targetDiv.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: dir === "next" ? "start" : "end",
        })
      }
    }
  }

  return (
    <section
      css={[
        tw`hidden justify-center items-center gap-5`,
        tw`lg:(flex overflow-hidden mt-2)`,
        tw`xl:(gap-10)`,
      ]}
    >
      <button
        disabled={scrollPosition === "start"}
        css={[tw`disabled:cursor-default`, hideButtons && tw`hidden`]}
        onClick={() => {
          scrollToFirstIncompleteDiv("prev")
        }}
      >
        <Icon.Chevron
          direction="left"
          color={scrollPosition !== "start" ? "#b2b2b2" : "#b2b2b266"}
          css={[tw`h-14`]}
        />
      </button>
      <div
        ref={containerRef}
        css={[
          tw`flex justify-start gap-5`,
          tw`lg:(overflow-x-scroll scrollbar-hide)`,
        ]}
      >
        {children}
      </div>
      <button
        disabled={scrollPosition === "end"}
        onClick={() => {
          scrollToFirstIncompleteDiv("next")
        }}
        css={[tw`disabled:cursor-default`, hideButtons && tw`hidden`]}
      >
        <Icon.Chevron
          direction="right"
          color={scrollPosition !== "end" ? "#b2b2b2" : "#b2b2b266"}
          css={[tw`h-14`]}
        />
      </button>
    </section>
  )
}
