import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { VehicleDetailOtherOffersProps } from "./InventoryDetail.d"
import { OfferCard } from "../../molecules/OfferCard"
import { Offer } from "../../../global"
import Icon from "../../atoms/Icon"
import { LanguageContext } from "../../../contexts/Language"
import { HorizontalScroller } from "../../molecules/HorizontalScroller"

/**
 * The "other offers" section of the Vehicle Detail page.
 * This was copied directly from the OfferDetailOtherOffers -- but it has a sanity bg image. that can be set by editors and a different offers type is expected??
 * @TODO refactor into a single component
 * @author Cody
 *
 * @param {Object} offers Offers object containing subarrays of up to 3 offers per
 * @returns <section></section>
 *  */

const VehicleDetailOtherOffers: React.FC<VehicleDetailOtherOffersProps> = ({
  offers,
}) => {
  // Offers grouped in 3s flattened into one array
  const allOffers = offers?.flat() ?? []
  const { language, _ } = useContext(LanguageContext)

  return (
    <section
      css={[
        tw`relative flex items-end min-h-[32rem] bg-black pb-40`,
        tw`lg:(min-h-0)`,
      ]}
      aria-label="Other Offers Section"
    >
      <section css={[tw`w-full`, tw`xl:(px-[4.5rem])`]}>
        <h3
          css={[
            tw`text-white text-3xl tracking-[3px] font-light ml-6 my-12`,
            tw`lg:(text-3xl tracking-[3.75px] my-[3.75rem])`,
          ]}
        >
          {_("Other Offers")}
        </h3>
        {/* Mobile component */}
        <section
          css={[
            tw`flex gap-5 w-full overflow-x-scroll pt-3 pl-6 scrollbar-hide`,
            tw`lg:(hidden)`,
          ]}
        >
          {allOffers?.map((node: Offer) => (
            <OfferCard
              {...node}
              cardType={node?.cardLabel}
              cardLabel={
                language === "es" ? node?.cardLabelES : node?.cardLabel
              }
            />
          ))}
        </section>
        {/* Desktop component */}
        <HorizontalScroller>
          {offers?.map((node: Offer, i: number) => (
            <OfferCard
              {...node}
              cardType={node?.cardLabel}
              cardLabel={
                language === "es" ? node?.cardLabelES : node?.cardLabel
              }
              css={[tw`transition-all duration-500`]}
              analyticsId={`offer details:other offers:${i + 1}`}
              key={i}
            />
          ))}
        </HorizontalScroller>
      </section>
    </section>
  )
}

export default VehicleDetailOtherOffers
