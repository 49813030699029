import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { LanguageContext } from "../../../contexts/Language"
import { parseDisclaimerBlocks } from "../../../helpers"
import { Accordion } from "../../atoms/Accordion"
import { SpecsProps } from "./InventoryDetail.d"

const Specs: React.FC<SpecsProps> = ({ vehicle }) => {
  const { standardEquipment } = vehicle
  const [state, modalDispatch] = useContext(DisclaimersContext)
  const { _ } = useContext(LanguageContext)
  const [indexIsTwo, setIndexTwo] = useState(false)

  // Logic needed for to determine index for analytics-id tagging
  useEffect(() => {
    if (
      Object.getOwnPropertyNames(standardEquipment?.specs).includes(
        "Mechanical/Performance"
      )
    ) {
      setIndexTwo(true)
    }
  }, [standardEquipment])

  return (
    <section
      aria-label="Vehicle Specifications Section"
      css={[tw`px-6 mt-12`, tw`md:(px-12 mt-12 mb-12)`]}
    >
      <h2 css={[tw`text-3xl font-light tracking-widest mb-3`]}>
        {_("Specifications")}
      </h2>
      {/* Mechanical/Performance Section */}
      {Object.entries(standardEquipment).map(se => {
        const [key, value] = se
        if (key === "specs") {
          return Object.entries(se[1])
            .filter(k => {
              const [key, value] = k
              return key === "Mechanical/Performance"
            })
            .map(v => {
              const [key, value] = v
              return (
                <Accordion
                  expanded={false}
                  label={key}
                  css={[
                    tw`border-t items-start border-gray-500 px-5 pl-0 py-6 mt-0`,
                    tw`md:(px-5 py-6 mb-0)`,
                  ]}
                  analyticsId={"specifications:1"}
                >
                  <ul
                    css={[
                      tw`grid grid-cols-12 text-left mr-0 border pt-2`,
                      tw`lg:(mr-7 mb-4)`,
                    ]}
                  >
                    {Object.entries(value).map(v => {
                      const [key, value] = v
                      return (
                        <>
                          <li
                            css={[
                              tw`ml-0 mb-2 col-span-12 border-b pb-2 pl-4`,
                              tw`md:(ml-6 col-span-2 pl-0)`,
                            ]}
                          >
                            <span css={tw`font-semibold`}>
                              {parseDisclaimerBlocks(key, selection =>
                                modalDispatch(toggleDisclaimersModal(selection))
                              )}
                            </span>
                          </li>
                          <li
                            css={[
                              tw`ml-0 mb-2 col-span-12 w-full border-b pb-2 p-4 md:(col-span-10 p-0 pr-4)`,
                            ]}
                          >
                            <span>
                              {parseDisclaimerBlocks(value, selection =>
                                modalDispatch(toggleDisclaimersModal(selection))
                              )}
                            </span>
                          </li>
                        </>
                      )
                    })}
                  </ul>
                </Accordion>
              )
            })
        }
      })}
      {/* Other Specifications Accordion */}
      <Accordion
        label={"Other Specifications"}
        css={[
          tw`border-t items-start border-gray-500 px-5 pl-0 py-6 mt-0`,
          tw`md:(px-5 py-6 mt-0)`,
        ]}
        analyticsId={`specifications:${indexIsTwo ? 2 : 1}`}
      >
        <div
          css={[
            tw`grid grid-cols-1 gap-x-8`,
            tw`lg:(grid grid-cols-2 gap-x-8)`,
          ]}
        >
          {Object.entries(vehicle.standardEquipment).map(se => {
            const [key] = se
            if (key === "specs") {
              return Object.entries(se[1])
                .filter(k => {
                  const [key] = k
                  return key !== "Mechanical/Performance"
                })
                .map(v => {
                  const [key, value] = v
                  return (
                    <div css={[tw`col-span-1 mb-8`]}>
                      <h4
                        css={tw`font-light text-xl tracking-widest mt-4 mb-2`}
                      >
                        {key}
                      </h4>
                      {Object.entries(value).map(v => {
                        const [key, value] = v
                        return (
                          <ul css={[tw`flex justify-between border`]}>
                            <li css={[tw`text-sm px-4 py-2`]}>
                              <span css={tw`font-semibold`}>
                                {parseDisclaimerBlocks(key, selection =>
                                  modalDispatch(
                                    toggleDisclaimersModal(selection)
                                  )
                                )}
                              </span>
                            </li>
                            <li css={[tw`text-sm px-4 py-2`]}>
                              <span>
                                {parseDisclaimerBlocks(value, selection =>
                                  modalDispatch(
                                    toggleDisclaimersModal(selection)
                                  )
                                )}
                              </span>
                            </li>
                          </ul>
                        )
                      })}
                    </div>
                  )
                })
            }
          })}
        </div>
      </Accordion>
    </section>
  )
}

export default Specs
