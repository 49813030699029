import React from "react"
import { IconProps } from "./Icon.d"

const SmartPathLogo: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg
      viewBox="0 0 196 52"
      fill="#FFF"
      xmlns="http://www.w3.org/2000/svg"
      {...remainingProps}
    >
      <path d="M0 0H53V52H0V0Z" fill="#EB0A1E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4744 14.2002C16.4758 14.2114 8.83072 19.2683 8.83072 26.0083C8.83072 32.7558 16.2921 37.7999 26.5079 37.7999C36.7247 37.7999 44.1031 32.5266 44.1031 26.0083C44.1031 19.4971 36.4577 14.2117 26.4744 14.2002ZM26.4744 14.2002L26.4417 14.2001H26.5079L26.4744 14.2002ZM37.4425 19.2608C37.8261 20.7185 36.09 22.5365 30.5318 23.1259C30.164 19.4238 29.0458 16.2958 27.1931 15.6248C29.5527 15.6777 31.8899 16.092 34.1206 16.8533C36.0563 17.5574 37.2586 18.4906 37.4593 19.2608H37.4425ZM26.4585 32.5916C27.7942 32.5916 29.2297 30.6436 29.2297 25.9422H29.2286C28.3449 26.0567 27.4264 26.0567 26.4585 26.0567C25.4907 26.0567 24.5721 26.0248 23.6705 25.9422C23.6705 30.6436 25.1228 32.5916 26.4585 32.5916ZM26.4585 23.322H23.8376C24.2716 20.2436 25.3897 18.408 26.4585 18.408C27.5262 18.408 28.6286 20.1929 29.0626 23.322H26.4585ZM18.7796 16.8533C16.8259 17.6224 15.6248 18.4741 15.4409 19.2608H15.4913C15.1235 20.7185 16.8427 22.5365 22.402 23.1259C22.7519 19.4238 23.8712 16.2969 25.7407 15.6248C23.3699 15.6788 21.0215 16.0931 18.7796 16.8533ZM11.4348 25.5819C11.4169 23.7088 12.0797 21.8919 13.3044 20.4562C13.4547 23.0929 17.094 25.0585 22.2686 25.746V25.9422C22.2686 32.2974 23.9374 35.6712 25.7744 36.3763C17.8611 36.0965 11.4348 31.3146 11.4348 25.5819ZM30.7483 25.9422C30.7483 32.0352 29.0795 35.6546 27.192 36.3752H27.2099C35.1389 36.113 41.582 31.3146 41.582 25.5819C41.5999 23.7099 40.9371 21.8919 39.7124 20.4562C39.5633 23.0929 35.9408 25.0585 30.7483 25.746V25.9422Z"
        fill="white"
      />
      <path d="M66.664 35.132C65.624 35.132 64.608 34.988 63.616 34.7C62.624 34.396 61.752 33.98 61 33.452L61.84 30.452C62.544 30.98 63.336 31.412 64.216 31.748C65.096 32.084 65.968 32.252 66.832 32.252C67.68 32.252 68.328 32.076 68.776 31.724C69.224 31.372 69.448 30.868 69.448 30.212C69.448 29.78 69.328 29.428 69.088 29.156C68.864 28.884 68.536 28.652 68.104 28.46C67.688 28.252 67.024 27.98 66.112 27.644C65.072 27.26 64.216 26.86 63.544 26.444C62.888 26.028 62.36 25.516 61.96 24.908C61.576 24.284 61.384 23.524 61.384 22.628C61.384 21.076 61.92 19.852 62.992 18.956C64.08 18.06 65.504 17.612 67.264 17.612C68.16 17.612 69.056 17.724 69.952 17.948C70.848 18.172 71.6 18.452 72.208 18.788L71.296 21.572C70.72 21.252 70.064 20.988 69.328 20.78C68.592 20.572 67.904 20.468 67.264 20.468C66.48 20.468 65.848 20.636 65.368 20.972C64.904 21.292 64.672 21.74 64.672 22.316C64.672 22.748 64.8 23.116 65.056 23.42C65.328 23.708 65.688 23.956 66.136 24.164C66.584 24.372 67.224 24.628 68.056 24.932C69.144 25.332 70 25.708 70.624 26.06C71.264 26.412 71.776 26.892 72.16 27.5C72.544 28.092 72.736 28.868 72.736 29.828C72.736 31.492 72.184 32.796 71.08 33.74C69.992 34.668 68.52 35.132 66.664 35.132Z" />
      <path d="M90.3511 22.484C91.7111 22.484 92.7351 22.884 93.4231 23.684C94.1271 24.484 94.4791 25.644 94.4791 27.164V34.892H91.4791V28.316C91.4791 27.244 91.2951 26.452 90.9271 25.94C90.5751 25.428 90.0071 25.172 89.2231 25.172C88.4071 25.172 87.7511 25.46 87.2551 26.036C86.7751 26.612 86.5351 27.372 86.5351 28.316V34.892H83.5351V28.316C83.5351 27.244 83.3511 26.452 82.9831 25.94C82.6311 25.428 82.0711 25.172 81.3031 25.172C80.4711 25.172 79.8151 25.46 79.3351 26.036C78.8551 26.612 78.6151 27.404 78.6151 28.412V34.892H75.6151V22.748H78.6151V24.62C79.0151 23.932 79.5431 23.404 80.1991 23.036C80.8711 22.668 81.6151 22.484 82.4311 22.484C83.4231 22.484 84.2391 22.708 84.8791 23.156C85.5191 23.588 85.9751 24.228 86.2471 25.076C86.6311 24.228 87.1831 23.588 87.9031 23.156C88.6231 22.708 89.4391 22.484 90.3511 22.484Z" />
      <path d="M103.113 22.508C104.729 22.508 105.993 22.9 106.905 23.684C107.817 24.452 108.273 25.636 108.273 27.236V34.892H105.297V33.212C104.897 33.788 104.337 34.26 103.617 34.628C102.897 34.98 102.089 35.156 101.193 35.156C99.9927 35.156 99.0327 34.836 98.3127 34.196C97.6087 33.54 97.2567 32.652 97.2567 31.532C97.2567 30.588 97.5367 29.836 98.0967 29.276C98.6727 28.7 99.5447 28.284 100.713 28.028C101.881 27.756 103.401 27.604 105.273 27.572V27.332C105.273 26.596 105.049 26.02 104.601 25.604C104.153 25.188 103.481 24.98 102.585 24.98C101.305 24.98 100.041 25.404 98.7927 26.252L98.0967 23.876C98.7367 23.476 99.4887 23.148 100.353 22.892C101.217 22.636 102.137 22.508 103.113 22.508ZM102.273 32.924C103.089 32.924 103.793 32.7 104.385 32.252C104.977 31.788 105.273 31.116 105.273 30.236V29.42C103.465 29.452 102.193 29.62 101.457 29.924C100.737 30.212 100.377 30.692 100.377 31.364C100.377 31.844 100.537 32.228 100.857 32.516C101.193 32.788 101.665 32.924 102.273 32.924Z" />
      <path d="M117.637 22.484C118.149 22.484 118.669 22.58 119.197 22.772L118.717 25.652C118.237 25.492 117.813 25.412 117.445 25.412C115.589 25.412 114.661 26.66 114.661 29.156V34.892H111.685V22.748H114.661V24.692C115.301 23.22 116.293 22.484 117.637 22.484Z" />
      <path d="M125.711 35.132C124.527 35.132 123.607 34.804 122.951 34.148C122.311 33.476 121.991 32.476 121.991 31.148V25.268H120.119V22.748H121.991V19.34L124.991 18.956V22.748H128.279V25.268H124.991V30.74C124.991 31.86 125.471 32.42 126.431 32.42C127.071 32.42 127.767 32.204 128.519 31.772L128.999 34.196C128.599 34.468 128.111 34.692 127.535 34.868C126.959 35.044 126.351 35.132 125.711 35.132Z" />
      <path d="M137.024 17.852C138.272 17.852 139.336 18.092 140.216 18.572C141.096 19.052 141.76 19.7 142.208 20.516C142.656 21.332 142.88 22.252 142.88 23.276C142.88 24.252 142.648 25.172 142.184 26.036C141.736 26.9 141.072 27.596 140.192 28.124C139.312 28.652 138.256 28.916 137.024 28.916H134.456V34.892H131.216V17.852H137.024ZM136.352 26.228C137.296 26.228 138.056 25.972 138.632 25.46C139.208 24.932 139.496 24.204 139.496 23.276C139.496 22.332 139.216 21.644 138.656 21.212C138.096 20.764 137.328 20.54 136.352 20.54H134.456V26.228H136.352Z" />
      <path d="M149.8 22.508C151.416 22.508 152.68 22.9 153.592 23.684C154.504 24.452 154.96 25.636 154.96 27.236V34.892H151.984V33.212C151.584 33.788 151.024 34.26 150.304 34.628C149.584 34.98 148.776 35.156 147.88 35.156C146.68 35.156 145.72 34.836 145 34.196C144.296 33.54 143.944 32.652 143.944 31.532C143.944 30.588 144.224 29.836 144.784 29.276C145.36 28.7 146.232 28.284 147.4 28.028C148.568 27.756 150.088 27.604 151.96 27.572V27.332C151.96 26.596 151.736 26.02 151.288 25.604C150.84 25.188 150.168 24.98 149.272 24.98C147.992 24.98 146.728 25.404 145.48 26.252L144.784 23.876C145.424 23.476 146.176 23.148 147.04 22.892C147.904 22.636 148.824 22.508 149.8 22.508ZM148.96 32.924C149.776 32.924 150.48 32.7 151.072 32.252C151.664 31.788 151.96 31.116 151.96 30.236V29.42C150.152 29.452 148.88 29.62 148.144 29.924C147.424 30.212 147.064 30.692 147.064 31.364C147.064 31.844 147.224 32.228 147.544 32.516C147.88 32.788 148.352 32.924 148.96 32.924Z" />
      <path d="M162.648 35.132C161.464 35.132 160.544 34.804 159.888 34.148C159.248 33.476 158.928 32.476 158.928 31.148V25.268H157.056V22.748H158.928V19.34L161.928 18.956V22.748H165.216V25.268H161.928V30.74C161.928 31.86 162.408 32.42 163.368 32.42C164.008 32.42 164.704 32.204 165.456 31.772L165.936 34.196C165.536 34.468 165.048 34.692 164.472 34.868C163.896 35.044 163.288 35.132 162.648 35.132Z" />
      <path d="M175.066 22.484C176.474 22.484 177.538 22.884 178.258 23.684C178.994 24.484 179.362 25.644 179.362 27.164V34.892H176.362V28.316C176.362 27.244 176.154 26.452 175.738 25.94C175.338 25.428 174.722 25.172 173.89 25.172C172.962 25.172 172.218 25.468 171.658 26.06C171.114 26.636 170.842 27.42 170.842 28.412V34.892H167.842V16.844H170.842V24.668C171.258 23.964 171.826 23.428 172.546 23.06C173.282 22.676 174.122 22.484 175.066 22.484Z" />
    </svg>
  )
}

export default SmartPathLogo
