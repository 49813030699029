import moment from "moment"
import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { Accordion } from "../../atoms/Accordion"
import { Modal } from "../../atoms/Modal"
import { AccessoryCardCarousel } from "../../molecules/AccessoryCard"
import { FeaturesProps } from "./InventoryDetail.d"
import { Image } from "../../atoms/Image"
import { Button, ButtonLink } from "../../atoms/Button"
import { LanguageContext } from "../../../contexts/Language"
import Icon from "../../atoms/Icon"

const Features: React.FC<FeaturesProps> = ({ vehicle }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [activeAccy, setActiveAccy] = useState(0)
  const [activeAccyIndex, setActiveAccyIndex] = useState(null)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  const accessories = vehicle?.accessories?.filter(
    (accy: { consumerVisibility: any }) => accy.consumerVisibility
  )
  const { _ } = useContext(LanguageContext)

  return (
    <section
      aria-label="Vehicle Features Section"
      css={[tw`px-6`, tw`md:(px-12)`]}
    >
      <h2 css={[tw`text-3xl font-light tracking-widest mb-3`]}>
        {_("Features")}
        <span css={tw`text-base tracking-normal`}>
          {parseDisclaimerBlocks(`[accessories]`, code =>
            modalDispatch(toggleDisclaimersModal(code))
          )}
        </span>
      </h2>
      {accessories && accessories.length > 0 && (
        <Accordion
          expanded={true}
          label="Accessories / Packages"
          css={[
            tw`border-t items-start border-gray-500 px-5 pl-0 py-6 mt-0`,
            tw`md:(px-5 py-6 mt-0)`,
          ]}
          analyticsId={"features:1"}
        >
          <ul
            css={[
              tw`block text-left mr-6 mb-6`,
              tw`md:(block text-left mr-12 mb-6 ml-5)`,
            ]}
          >
            {accessories.map(
              (
                accy: {
                  id: string
                  name: string
                  msrp: string
                  extendedDescription: string
                },
                i
              ) => (
                <li key={accy?.id} css={[tw`ml-0 mb-6 block`]}>
                  <div css={[tw`flex justify-between gap-4 mb-2`]}>
                    <button
                      onClick={() => {
                        setActiveAccy(accy)
                        setActiveAccyIndex(i)
                        setModalOpen(!modalOpen)
                      }}
                      css={[tw`font-semibold text-red-500`]}
                    >
                      {accy.name}
                    </button>
                    {accy.msrp && <span>${accy.msrp}</span>}
                  </div>
                  <span
                    css={[
                      tw`text-gray-700 w-full block`,
                      tw`md:(text-gray-700 w-4/5 block)`,
                    ]}
                  >
                    {accy.extendedDescription &&
                      accy.extendedDescription
                        .split("|")
                        .map((desc: string) => (
                          <li css={[tw`mb-2`]}>{desc}</li>
                        ))}
                  </span>
                </li>
              )
            )}
            {modalOpen && (
              <Modal
                open={modalOpen}
                closeStyled={false}
                onClose={() => {
                  setModalOpen(!modalOpen)
                }}
                css={[tw`w-full h-screen md:(w-[37rem] h-auto)`]}
              >
                {activeAccy && (
                  <div css={[tw`pt-0 pb-8`]}>
                    <div
                      css={[
                        tw`pb-8 pt-4 text-center text-base tracking-widest font-light`,
                      ]}
                    >
                      {_("Installed Accessory")} ({activeAccyIndex + 1}{" "}
                      {_("of")} {accessories.length})
                    </div>
                    <div css={[tw`mb-4 flex mx-8`, tw`md:(mx-16)`]}>
                      <button
                        css={[tw`w-4 inline-block`]}
                        onClick={() => {
                          if (activeAccyIndex == 0) {
                            setActiveAccy(accessories[accessories.length - 1])
                            setActiveAccyIndex(accessories.length - 1)
                          } else {
                            setActiveAccy(accessories[activeAccyIndex - 1])
                            setActiveAccyIndex(activeAccyIndex - 1)
                          }
                        }}
                      >
                        <Icon.Chevron
                          direction="left"
                          color="black"
                          css={[
                            tw`w-4 transition-transform duration-500 mt-1.5`,
                          ]}
                        />
                      </button>
                      <h2
                        css={[
                          tw`font-semibold text-center uppercase text-xl tracking-widest max-w-xs flex-1 mx-4`,
                          tw`md:(mx-12 max-w-lg text-2xl)`,
                        ]}
                      >
                        {activeAccy.name}
                      </h2>
                      <button
                        css={[tw`w-4 inline-block`]}
                        onClick={() => {
                          if (activeAccyIndex == accessories.length - 1) {
                            setActiveAccy(accessories[0])
                            setActiveAccyIndex(0)
                          } else {
                            setActiveAccy(accessories[activeAccyIndex + 1])
                            setActiveAccyIndex(activeAccyIndex + 1)
                          }
                        }}
                      >
                        <Icon.Chevron
                          direction="right"
                          color="black"
                          css={[
                            tw`w-4 transition-transform duration-500 mt-1.5`,
                          ]}
                        />
                      </button>
                    </div>

                    {activeAccy.media && activeAccy.media.length > 0 && (
                      <div
                        css={tw`relative overflow-visible shadow-1 sm:shadow-4`}
                      >
                        <div
                          css={[
                            tw`w-full h-auto md:(bg-gray-100 overflow-hidden)`,
                          ]}
                        >
                          {activeAccy.media.length > 1 ? (
                            <AccessoryCardCarousel
                              slides={activeAccy.media?.map(
                                (mediaItem: any) => ({
                                  src: mediaItem?.locationAddress,
                                  alt: mediaItem?.alt,
                                })
                              )}
                            />
                          ) : (
                            <Image
                              src={activeAccy.media[0]?.locationAddress}
                              alt={""}
                              objectFit="cover"
                              css={[tw`w-full`]}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    <div css={[tw`flex justify-center px-8 pt-8 gap-4`]}>
                      <span
                        css={[tw`text-lg pb-4 text-center font-light w-1/3`]}
                      >
                        <span css={[tw`block font-semibold uppercase`]}>
                          {_("MSRP")}
                        </span>
                        ${activeAccy.msrp}
                      </span>
                      <span
                        css={[tw`text-lg pb-4 text-center font-light w-1/3`]}
                      >
                        <span css={[tw`block  font-semibold`]}>
                          {_("Category")}
                        </span>
                        {activeAccy.subCategory}
                      </span>
                    </div>
                    <span
                      css={[
                        tw`text-gray-700 w-full block px-8`,
                        tw`md:(text-gray-700 w-4/5 block)`,
                      ]}
                    >
                      {activeAccy.extendedDescription &&
                        activeAccy.extendedDescription
                          .split("|")
                          .map((desc: string) => (
                            <li css={[tw`mb-2`]}>{desc}</li>
                          ))}
                    </span>
                    <div css={[tw`pt-4 text-center text-sm font-light`]}>
                      <ButtonLink primary to={"/accessories"}>
                        {_("Learn More About Accessories")}
                      </ButtonLink>
                    </div>
                  </div>
                )}
              </Modal>
            )}
          </ul>
        </Accordion>
      )}

      {Object.entries(vehicle.standardEquipment).map(se => {
        const [key, value] = se
        if (key === "features") {
          return Object.entries(se[1]).map((v, index) => {
            const [key, value] = v
            return (
              <Accordion
                expanded={false}
                label={key}
                css={[
                  tw`border-t items-start border-gray-500 px-5 pl-0 py-6 mt-0`,
                  tw`md:(px-5 py-6 mt-0)`,
                ]}
                analyticsId={`features:${index + 2}`}
              >
                <ul css={[tw`text-left mr-7 ml-10 grid grid-cols-2`]}>
                  {Array.isArray(value) &&
                    value.map(v => (
                      <li css={[tw`mb-4 list-disc list-outside md:( mr-6)`]}>
                        {parseDisclaimerBlocks(v, selection =>
                          modalDispatch(toggleDisclaimersModal(selection))
                        )}
                      </li>
                    ))}
                </ul>
              </Accordion>
            )
          })
        }
      })}
    </section>
  )
}

export default Features
