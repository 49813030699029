import React, { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import {
  firstLettersCapital,
  generateInventoryLink,
  parseDisclaimerBlocks,
  remove4by,
  sanitizeSeries,
  smartPathDealersMap,
  toUsd,
} from "../../../helpers"
import Icon from "../../atoms/Icon"
import { Toyota } from "../../atoms/Logo"
import { VehicleInfoProps } from "./InventoryDetail.d"
import { Image } from "../../atoms/Image"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { ShareButton } from "../../atoms/ShareButton"
import { Link } from "../../atoms/Link"
import { LanguageContext } from "../../../contexts/Language"
import { Button, ButtonLink } from "../../atoms/Button"
import {
  openContactDealerModal,
  updateField,
} from "../../../contexts/Contact/actions"
import { ContactContext } from "../../../contexts/Contact"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import moment from "moment"
import { graphql, useStaticQuery } from "gatsby"
import useSalesEventsData from "../../../hooks/useSalesEventsData"
import { SalesEvent } from "../../../global"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import {
  addFavoritedVehicle,
  removeFavoritedVehicle,
  setFavoriteMessage,
} from "../../../contexts/Favorites/actions"
import { css } from "@emotion/react"
import SmartPathLogo from "../../atoms/Icon/SmartPathLogo"
import { LocationContext } from "../../../contexts/Location"

const VehicleInfo: React.FC<VehicleInfoProps> = ({ vehicle }) => {
  const content = useRef(null)
  const [isExpanded, setisExpanded] = useState(true)

  // Commenting out salesEvent until it can be styled.
  // const salesEvent = useSalesEventsData()[0]
  const salesEvent: SalesEvent = null

  const [state, modalDispatch] = useContext(DisclaimersContext)
  const { _, language } = useContext(LanguageContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const [{ zip }] = useContext(LocationContext)
  const interestedInTranslation = _("I'm interested in test driving the")
  const vinTranslation = _("VIN")
  const formattedEndDate = salesEvent?.formattedEndDate
  const exteriorColor = vehicle?.color?.exterior?.hex?.split(",")
  const vehicleIsCurrentlyAvailable =
    vehicle?.available?.toLowerCase() === "now"

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { updateContactDealerData, vehicleTealData } = useTealiumContext()
  const dealerName = firstLettersCapital(vehicle?.dealerName)
  // Model name includes 4x designation for trucks, whereas seriesName excludes it
  const modelName = vehicle?.series
  const seriesName = remove4by(vehicle?.series)

  const data = useStaticQuery(graphql`
    query VehicleInfoQuery {
      allDealers: allDealer {
        nodes {
          Name
          DealerCode
          DealerFee
        }
      }
    }
  `)

  const { allDealers } = data

  let selectDealer = allDealers?.nodes?.filter(
    (dealer: { DealerCode: string }) => dealer.DealerCode == vehicle.dealer
  )

  if (Array.isArray(selectDealer)) {
    selectDealer = selectDealer[0]
  } else {
    selectDealer = null
  }

  const priceWithDealerFee = vehicle?.msrp + selectDealer?.DealerFee

  const { vehicleEligibility, available, year, series, grade, vin } = vehicle

  let smartPathLink = smartPathDealersMap?.get(selectDealer?.DealerCode)
  if (smartPathLink) {
    smartPathLink += `/inventory/details?dealerCd=${selectDealer?.DealerCode}&vin=${vehicle?.vin}&zip=${zip}&source=t2&type=new`
  }

  const [favoritesState, dispatch] = useContext(FavoritesContext)
  const [isFavorited, setIsFavorited] = useState(false)

  useEffect(() => {
    if (favoritesState?.favoritedVehicles?.includes(vin)) {
      setIsFavorited(true)
    }
  }, [])

  // Boolean allows favorite icon to display for sold + recently viewed only if it is already a favorite.
  // Option to "favorite" a sold vehicle in recently viewed is also disabled by this.
  const availableOrFavorite = vehicleEligibility || isFavorited

  // Determine if vehicle is in "recently viewed"
  const recentlyViewed = LocalStorageClient?.read("recentlyViewed")
  const isRecentlyViewed: boolean = recentlyViewed?.includes(vin)

  const inventoryLinkOptions = {
    series: sanitizeSeries(vehicle?.series),
    modelNumber: vehicle?.modelNumber,
    year: vehicle?.year,
  }

  return (
    <section
      aria-label="Vehicle Information Section"
      css={[
        tw`w-full h-auto py-6 px-6 row-start-2`,
        tw`lg:(w-[32rem] h-auto rounded-r-2xl shadow-4 pt-12)`,
      ]}
    >
      <div css={[tw`relative`, tw`(lg:px-4)`]}>
        <div css={[tw`absolute top-8 right-0 z-10 p-2 flex justify-end`]}>
          {vehicleEligibility && (
            <ShareButton
              url={`/inventory/${vin}`}
              title={`${year && year} ${series && series} ${grade && grade}`}
              iconStyles={tw`h-5 w-5 mb-1.5 mr-3`}
            />
          )}

          {availableOrFavorite && (
            <button
              onClick={() => {
                const newState = !isFavorited

                if (newState === true) {
                  dispatch(addFavoritedVehicle(vin))
                  dispatch(setFavoriteMessage("inventory"))
                } else {
                  dispatch(removeFavoritedVehicle(vin))
                }
                setIsFavorited(newState)
              }}
              aria-label={isFavorited ? `Unfavorite` : `Favorite`}
            >
              <Icon.Heart
                color={isFavorited ? "red-400" : "gray-900"}
                css={[
                  tw`h-6`,
                  css`
                    filter: drop-shadow(0 3px 3px rgb(10, 22, 70, 0.15))
                      drop-shadow(0 0 1px rgb(10, 22, 70, 0.06));
                  `,
                ]}
                filled={isFavorited}
              />
            </button>
          )}
        </div>
        {language === "en" ? (
          <h1 css={[tw`mr-16`]}>
            <span css={[tw`block font-semibold text-lg`]}>{vehicle.year}</span>
            <span
              css={[
                tw`font-semibold tracking-widest text-4xl uppercase w-full overflow-hidden max-w-[400px] overflow-ellipsis`,
              ]}
            >
              {vehicle.series} {vehicle.grade}
            </span>
          </h1>
        ) : (
          <h1>
            <span
              css={[
                tw`block font-semibold tracking-widest text-4xl uppercase w-full overflow-hidden overflow-ellipsis`,
              ]}
            >
              {vehicle.series} {vehicle.grade}
            </span>
            <span css={[tw`font-semibold text-lg block`]}>{vehicle.year}</span>
          </h1>
        )}

        <span css={[tw`font-light text-4xl`]}>
          ${toUsd(priceWithDealerFee)}
          {parseDisclaimerBlocks(`[total_msrp]`, code =>
            modalDispatch(toggleDisclaimersModal(code))
          )}
        </span>
        {/*Color swatch section*/}
        <div css={[tw`flex items-start my-4`]}>
          <span css={[tw`font-semibold text-sm uppercase`]}>{_("EXT")}</span>
          {exteriorColor && (
            <span
              css={[
                tw`h-5 w-5 inline-block mx-2 border`,
                exteriorColor?.length === 1 &&
                  `background-color: #${exteriorColor[0]}`,
                exteriorColor?.length === 2 &&
                  `background: linear-gradient(45deg, #${exteriorColor[0]} 0, #${exteriorColor[0]} 50%, #${exteriorColor[1]} 50%, #${exteriorColor[1]} 100%)`,
              ]}
            ></span>
          )}
          <span css={[tw`font-light text-sm`]}>
            {parseDisclaimerBlocks(vehicle.color.exterior.name, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </span>

          <span css={[tw`font-semibold text-sm ml-12 uppercase`]}>
            {_("INT")}
          </span>
          <span css={[tw`h-5 w-5 inline-block mx-2 border`]}>
            {vehicle.color?.interior?.imageUrl && (
              <Image
                src={vehicle.color.interior.imageUrl}
                alt={vehicle.color.interior.name}
                css={[tw`h-full w-full`]}
              />
            )}
          </span>
          <span css={[tw`font-light text-sm`]}>
            {parseDisclaimerBlocks(vehicle.color.interior.name, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </span>
        </div>
        {vehicleEligibility && (
          <div>
            <span css={[tw`text-sm font-semibold uppercase`]}>{_("VIN")}</span>{" "}
            <span css={[tw`text-sm font-light`]}>{vehicle.vin}</span>
          </div>
        )}
        <div css={[tw`mt-6`]}>
          <button
            type="button"
            css={[
              tw`flex items-center w-full border-b pb-2`,
              "-webkit-tap-highlight-color: transparent;",
            ]}
            aria-expanded={isExpanded}
            onClick={() => setisExpanded(!isExpanded)}
          >
            <span css={[tw`font-semibold `]}>{_("Detailed Pricing")}</span>
            <Icon.Chevron
              color="red-400"
              direction="down"
              css={[
                tw`h-1.5 transition-all duration-300 mt-1 ml-2`,
                isExpanded && tw`transform -rotate-180`,
              ]}
            />
          </button>
          <div
            ref={content}
            css={[
              tw`transition-all ease-out duration-500 opacity-100 h-0 py-2`,
              isExpanded ? `height: auto` : tw`h-0 opacity-0`,
            ]}
          >
            <div css={[tw`flex flex-row justify-between`]}>
              <span>
                {_("Base MSRP")}
                {parseDisclaimerBlocks(`[base_msrp]`, code =>
                  modalDispatch(toggleDisclaimersModal(code))
                )}
              </span>
              <span>${toUsd(vehicle.pricing.baseModelMsrp)}</span>
            </div>
            {vehicle.pricing.pioAccyPriceAmount ? (
              <div css={[tw`flex flex-row justify-between`]}>
                <span>
                  {_("Packages & Accessories")}
                  {parseDisclaimerBlocks(`[accessories]`, code =>
                    modalDispatch(toggleDisclaimersModal(code))
                  )}
                </span>

                <span>
                  $
                  {toUsd(
                    vehicle.pricing.pioAccyPriceAmount +
                      vehicle.pricing.fiaAccyPriceAmount
                  )}
                </span>
              </div>
            ) : (
              <div css={[tw`flex flex-row justify-between`]}>
                <span>
                  {_("Packages & Accessories")}
                  {parseDisclaimerBlocks(`[accessories]`, code =>
                    modalDispatch(toggleDisclaimersModal(code))
                  )}
                </span>
                <span>{_("Pricing not available")}</span>
              </div>
            )}
            <div css={[tw`flex flex-row justify-between`]}>
              <span>{_("Destination Charges")}</span>
              <span>${toUsd(vehicle.pricing.deliveryProcessingHandling)}</span>
            </div>

            <div css={[tw`flex flex-row justify-between`]}>
              <span>{_("Dealer Fee")}</span>
              <span>
                {selectDealer?.DealerFee
                  ? `$${toUsd(selectDealer?.DealerFee)}`
                  : _("Pricing not available")}
              </span>
            </div>
            <div css={[tw`flex flex-row justify-between border-b pb-2`]}>
              <span css={[tw`font-semibold`]}>
                {_("Total")}
                {parseDisclaimerBlocks(`[total_msrp]`, code =>
                  modalDispatch(toggleDisclaimersModal(code))
                )}
              </span>
              <span>${toUsd(priceWithDealerFee)} </span>
            </div>
            <div css={[tw`flex flex-row justify-between items-center py-4`]}>
              <div css={[tw`flex items-center`]}>
                <Toyota showText={false} />
                <span css={[tw`font-semibold text-lg ml-2`]}>
                  <Link animated to={"/toyotacare"}>
                    {_("ToyotaCare")}
                  </Link>
                </span>
                {parseDisclaimerBlocks(`[toyota_care]`, code =>
                  modalDispatch(toggleDisclaimersModal(code))
                )}
              </div>
              <span>$0 ({_("No Cost")})</span>
            </div>
            <div css={[tw`border-b pb-2 text-center`]}>
              <span css={[tw`italic text-xs`]}>
                {_("No cost maintenance plan and roadside assistance.")}
              </span>
            </div>
          </div>
          {salesEvent && salesEvent.salesEventActive && (
            <div>
              <Image
                src={salesEvent.eventImage.image.asset.gatsbyImageData}
                alt={""}
              />
              <span>
                {_("Event Ends")} {formattedEndDate}
              </span>
            </div>
          )}
          {vehicleEligibility ? (
            <span
              css={[
                tw`font-semibold text-sm block mt-2 mb-6 whitespace-nowrap w-full`,
              ]}
              className="optimizelyInventoryResultCardAvailability"
            >
              {_("Available")} {available}
            </span>
          ) : (
            <span
              css={[
                tw`font-semibold text-sm block mt-2 mb-6 whitespace-nowrap w-full`,
              ]}
            >
              {_("SOLD")}
            </span>
          )}
          {vehicle?.available?.toLowerCase() === "now" && selectDealer && (
            <div css={[tw`mt-5 w-full flex items-center`]}>
              <Icon.Dealer css={[tw`max-h-12`]} />
              <span css={[tw`inline-block pb-4 pl-4 font-semibold`]}>
                {dealerName}
              </span>
            </div>
          )}
          <div css={[tw`grid gap-y-3 sm:(flex gap-3)`]}>
            {vehicleEligibility ? (
              <Button
                primary
                css={[tw`block w-full sm:(inline !px-4)`]}
                analytics-id="contact:info:"
                onClick={() => {
                  contactDispatch(updateField("comments", " "))
                  contactDispatch(
                    openContactDealerModal(
                      vehicleIsCurrentlyAvailable && selectDealer
                        ? selectDealer
                        : null,
                      vehicle,
                      "New Inventory Availability",
                      "CheckAvailability",
                      vehicleIsCurrentlyAvailable && selectDealer ? true : false
                    )
                  )
                  trackTealEvent({
                    tealium_event: "contact_form_nav",
                    contact_question: "Check Availability",
                    dropdown_selected: "Contact Dealer",
                    dealer_name: _contactState?.dealer?.Name,
                    dealer_code: _contactState?.dealer?.DealerCode,
                    allocated_dealer_name: dealerName,
                    allocated_dealer_code: vehicle.dealer,
                    vehicle_exterior_color: vehicle.color.exterior.name,
                    vehicle_interior_color: vehicle.color.interior.name,
                    vehicle_model: modelName,
                    vehicle_model_truncated: seriesName,
                    vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                    vehicle_segment_vehicle_page:
                      vehicleTealData.vehicle_segment_vehicle_page,
                    vehicle_trim: vehicle.grade,
                    vehicle_trim_id: vehicle.modelNumber,
                    vehicle_vin: vehicle.vin,
                    vehicle_year: vehicle.year,
                  })
                  updateContactDealerData({
                    fromInventory: {
                      vehicle_model: modelName,
                      vehicle_model_truncated: seriesName,
                      vehicle_exterior_color: vehicle.color.exterior.name,
                      vehicle_interior_color: vehicle.color.interior.name,
                      vehicle_trim: vehicle.grade,
                      vehicle_trim_id: vehicle.modelNumber,
                      vehicle_vin: vehicle.vin,
                      vehicle_year: vehicle.year,
                      vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                      vehicle_segment_vehicle_page:
                        vehicleTealData.vehicle_segment_vehicle_page,
                    },
                    dealerData: {
                      dealer_name: _contactState?.dealer?.Name,
                      dealer_code: _contactState?.dealer?.DealerCode,
                    },
                    allocatedDealer: {
                      allocated_dealer_name: dealerName,
                      allocated_dealer_code: vehicle.dealer,
                    },
                  })
                }}
                className="optimizelyInventoryResultCardCheckAvailability"
              >
                {_("Check Availability")}
              </Button>
            ) : (
              <ButtonLink
                primary
                css={[tw`block w-full sm:(inline !px-4) whitespace-nowrap`]}
                to={generateInventoryLink(inventoryLinkOptions)}
              >
                {_("View Similar")}
              </ButtonLink>
            )}
            {vehicleEligibility && (
              <Button
                secondary
                css={[tw`block w-full sm:(inline !px-4)`]}
                analytics-id="schedule drive:info:"
                onClick={() => {
                  contactDispatch(
                    updateField(
                      "comments",
                      `${interestedInTranslation} ${vehicle.series} ${vehicle.grade}, ${vinTranslation} #${vehicle.vin}.`
                    )
                  )
                  contactDispatch(
                    openContactDealerModal(
                      vehicleIsCurrentlyAvailable && selectDealer
                        ? selectDealer
                        : null,
                      vehicle,
                      "New Inventory Availability",
                      null,
                      vehicleIsCurrentlyAvailable && selectDealer ? true : false
                    )
                  )
                  trackTealEvent({
                    tealium_event: "schedule_test_drive",
                    vehicle_model: modelName,
                    vehicle_model_truncated: seriesName,
                    vehicle_exterior_color: vehicle.color.exterior.name,
                    vehicle_interior_color: vehicle.color.interior.name,
                    vehicle_trim: vehicle.grade,
                    vehicle_trim_id: vehicle.modelNumber,
                    vehicle_vin: vehicle.vin,
                    allocated_dealer_name: dealerName,
                    allocated_dealer_code: vehicle.dealer,
                    vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                    vehicle_segment_vehicle_page:
                      vehicleTealData.vehicle_segment_vehicle_page,
                    vehicle_year: vehicle.year,
                  })
                  updateContactDealerData({
                    fromInventory: {
                      vehicle_model: modelName,
                      vehicle_model_truncated: seriesName,
                      vehicle_exterior_color: vehicle.color.exterior.name,
                      vehicle_interior_color: vehicle.color.interior.name,
                      vehicle_trim: vehicle.grade,
                      vehicle_trim_id: vehicle.modelNumber,
                      vehicle_vin: vehicle.vin,
                      vehicle_year: vehicle.year,
                      vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                      vehicle_segment_vehicle_page:
                        vehicleTealData.vehicle_segment_vehicle_page,
                    },
                    dealerData: {
                      dealer_code: vehicle.dealer,
                      dealer_name: dealerName,
                    },
                    allocatedDealer: {
                      allocated_dealer_name: dealerName,
                      allocated_dealer_code: vehicle.dealer,
                    },
                  })
                }}
              >
                {_("Schedule a Test Drive")}
              </Button>
            )}
          </div>
          {vehicle?.available?.toLowerCase() === "now" && smartPathLink && (
            <div css={tw`relative`}>
              <ButtonLink
                lightGray
                to={smartPathLink}
                css={tw`flex items-center justify-center mt-4 gap-2 overflow-visible z-0`}
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "visit_dealer_website",
                    vehicle_model: modelName,
                    vehicle_model_truncated: seriesName,
                    vehicle_exterior_color: vehicle.color?.exterior?.name,
                    vehicle_interior_color: vehicle.color?.interior?.name,
                    vehicle_trim: vehicle.grade,
                    vehicle_trim_id: vehicle.modelNumber,
                    vehicle_vin: vehicle?.vin,
                    allocated_dealer_name: dealerName,
                    allocated_dealer_code: vehicle?.dealer,
                    dealer_name: dealerName,
                    dealer_code: vehicle?.dealer,
                    vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                    vehicle_segment_vehicle_page:
                      vehicleTealData.vehicle_segment_vehicle_page,
                    vehicle_year: vehicle?.year,
                    link_href: smartPathLink,
                    coupon_module_text: "Available on Smart path",
                  })
                }}
                analytics-id="check availability:info:"
              >
                <span>{_("Available On")}</span>
                <SmartPathLogo css={tw`w-28 fill-current`} />
              </ButtonLink>
              <span
                css={[
                  tw`absolute top-1/2 right-6 transform -translate-y-1/2`,
                  tw`sm:(right-40)`,
                  tw`md:(right-56)`,
                  tw`lg:(right-20)`,
                ]}
              >
                {parseDisclaimerBlocks(`[smartpath]`, code =>
                  modalDispatch(toggleDisclaimersModal(code))
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
export default VehicleInfo
